import styled, {keyframes} from 'styled-components';
import Layout from 'components/Layout';
import StyledContentType from 'components/Layout/Content/types';
import StyledCopy from 'components/Copy/styles';
import TiledSand from 'assets/images/flower-repeatable-pattern-small.jpg';
import TopSand from 'assets/images/flowers-top.png';
import Image from 'components/Image';
import BottomSand from 'assets/images/flower-bottom.png';

export const StyledContent = styled(Layout.Content)(
  ({ theme: { colors  }}: StyledContentType) => `
    position: relative;
    ${StyledCopy} {
      background: ${colors.blue};
      color: ${colors.black};
      margin: auto;
      padding: 16px;
    }
`,
);

export const HeroLandscape = styled.div(
  ({ theme: { colors, mobileView } }) => `
    position: relative;
    height: 20vh;
    ${mobileView} {
      height: 10vh;
    }
    .mountain-left, .mountain-right {
      position: absolute;
      bottom: 0;
      width: auto;
      max-width: 45vw;
      z-index: 2;
      height: 50%;
      max-height: 150px;
      ${mobileView} {
        max-width: 100%;
      }
    }
    .mountain-left {
      left: 40%;
      transform: translate(-100%, -1vw);
      ${mobileView} {
      }
    }
    .mountain-right {
      right: 40%;
      transform: translate(100%, -1vw);
      ${mobileView} {
      }
    }
    .tree {
      position: absolute;
      width: 500px;
      max-width: 25vw;
      bottom: 0;
      z-index: 3;
      left: 0;
      transform: translate(-35%, 0);
    }
    .trees {
      position: absolute;
      right: 0;
      max-width: 210px;
      width: 28vw;
      bottom: 0;
      z-index: 2;
      transform: translate(-10%, 10%);
    }
    .edge {
      position: absolute;
      bottom:0;
      transform: translateY(10%);
      width: 100%;
      z-index: 2;
    }
`,
);

export const HeroBackground = styled.div(
  ({ theme: { colors, spacing, mobileView } }) => `
    position: relative;
    padding-top: ${spacing.x4};
    margin-top: -5px;
    background: ${colors.black};
    ${mobileView} {
      padding-top: ${spacing.x8};
    }
    .starBurst {
      position: absolute;
      top: 10%;
      left: 40%;
      z-index: 10;
      max-width: 15vw;
      transform: translate(-25vw, 0);
      ${mobileView} {
       top: 20%;
       max-width: 15vw;
      }
    }
    .ss-tag {
      display: block;
      position: relative;
      z-index: 10;
      margin: auto;
      width: 350px;
      max-width: 20%;
      margin-bottom: 16px;
      ${mobileView} {
        max-width: 30%;
      }
    }
    .bgLegLeft {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2; 
      bottom: 0;
      height: 100%;
      width: auto;
      transform: translate(-40%, 0);
      ${mobileView} {
        display: none;
      }
    }
    .bgLegRight {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      height: 100%;
      width: auto;
      ${mobileView} {
        display: none;
      }
    }
  `);

export const BlockBackground = styled.div(
  ({ theme: { tabletView, mobileView, spacing } }) => `
      background-image: url(${TiledSand});
      background-position: center;
      background-size: 100% auto;
      position: relative;
      z-index: 5;
      margin-top: 5vw;
      margin-bottom: 6vw;
      padding-top: ${spacing.x6};
      padding-bottom: 5%;
      > section {
        position: relative;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: calc(100% - 1px);
        left: 0;
        width: 100%;
        background-image: url(${TopSand});
        background-size: 100% auto;
        background-repeat: repeat-x;
        background-position: bottom center;
        padding-top: 7.5vw;
        z-index: -1;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-image: url(${BottomSand});
        background-size: 101% auto;
        background-position: bottom center;
        background-repeat: no-repeat;
        padding-top: 26%;
        z-index: -1;
        transform: translateY(6vw);
      }
      .manStanding {
        position: absolute;
        width: 60px;
        max-width: 10%;
        top: 5%;
        left: 50%;
        transform: translate(-50%, -100%);
        ${mobileView}{
          display: none;
        }
      }
      .armsUpDancer {
        position: absolute;
            width: 90px;
            max-width: 10%;
            bottom: 110%;
            left: 57%;
            transform: translateX(-50%);
            ${mobileView}{
              dispplay: none;
            }
      }
          .dancingGuy {
            position: absolute;
            width: 150px;
            max-width: 10%;
            bottom: 102%;
            left: 18%;
            transform: translateX(-50%);
            ${mobileView}{
              dispplay: none;
            }
          }
          .twoDancers {
            position: absolute;
            width: 70px;
            max-width: 6vw;
            bottom: 106%;
            left: 42%;
            transform: translateX(-50%);
            ${mobileView}{
              dispplay: none;
            }
          }
          .dancingCouple {
            position: absolute;
            z-index: 1;
            width: 100px;
            max-width: 8vw;
            top: 5%;
            left: 30%;
            transform: translate(-50%, -95%);
          }
          .dancingWomanDress {
            position: absolute;
            width: 150px;
            max-width: 10%;
            bottom: 105%;
            left: 30%;
            transform: translateX(-50%);
            ${mobileView}{
              dispplay: none;
            }
          }
          .dancingWomen {
            position: absolute;
            top: 0;
            transform: translateY(-75%);
            right: 25%;
            max-width: 12vw;
            width: 250px;
            ${mobileView} {
              display: none;
            }
          }
          .womanYellow {
            position: absolute;
            top: -10%;
            transform: translateY(-100%);
            right: 20%;
            max-width: 5vw;
            width: 100px;
            ${mobileView}{
              max-width: 10vw;
              bottom: 15%;
              right: 20%;
            }
          }
          .womanYellowPantsLeft {
            position: absolute;
            width: 400px;
            max-width: 25%;
            bottom: 0;
            left: 0;
            transform: translateX(-50%);
            ${mobileView} {
              display: none;
            }
          }
          .womanJeasRight {
            position: absolute;
            width: 200px;
            max-width: 13vw;
            bottom: 50%;
            left: 95%;
            transform: translate( -50% ,15%);
            ${tabletView} {
              display: none;
            }
          }
          ${mobileView} {
            > section {
              top: ${spacing.x2};
            }
            .womanCamera, .manVan. mushroomRight {
              display: none;
            }
          }
  `
)

export const LeftElements = styled.div(
  ({ theme: { mobileView, tabletView, spacing } }) => `
      position: absolute;
      bottom: 95%;
      right: 85%;
      max-width: 320px;
      width: 15vw;
      ${tabletView}{
        display: none;
      }
      .mushroomLeft {
        width: 100%;
        z-index: 0;
      }
      .coupleDancing {
        position: absolute;
        z-index: 1;
        width: 32%;
        bottom: 90%;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .womanSitting {
        position: absolute;
        z-index: 1;
        width: 38%;
        bottom: 75%;
        left: 30%;
        transform: translate(-50%, 0);
      }
      .womanLeaning {
        position: absolute;
        left: 50%;
        bottom: 0;
        max-width: 250px;
        transform: translate(0, 20%);
      }
`);
const swing = keyframes`
0%, 100% { transform: rotate(-15deg); }
50% { transform: rotate(15deg); }
`;
export const WomanSwing = styled(Image)`
  animation: 3s ease-in-out infinite ${swing};
`;
export const RightElements = styled.div(
  ({ theme: { tabletView } }) => `
  position: absolute;
  bottom: 100%;
  right: 0;
  max-width: 200px;
  width: 12vw;
  ${tabletView}{
    display: none;
  }
  .mushroomRight {
  }
  .womanCamera {
    position: absolute;
    bottom: -25%;
    right: 0;
    max-width: 250px;
    width: 35vw;
    @media only screen and (max-width: 639px){
      bottom: -50%;

    }
  }
  .womanTea {
    position: absolute;
    bottom: 95%;
    left: 40%;
    width: 30%;
    transform: translate(-50%, 0);
    transform-origin: 50% 100%;
  }
`);

export const BgVideo = styled.video`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;