import React from 'react';
import Layout from 'components/Layout';
import { Image as LogoImage} from 'components/BigLogo';
import { StyledContent, HeroLandscape, HeroBackground, BlockBackground, BgVideo, LeftElements, RightElements, WomanSwing } from './styles';
import SSPresents from 'assets/images/SSPresents.png';

import MountainLeft from 'assets/images/cityscape-left-cropped.png';
import MountainRight from 'assets/images/cityscape-right-cropped.png';
import Trees from 'assets/images/pandanas.png';
import LeftLeg from 'assets/images/big-leg-front.png';
import RightLeg from 'assets/images/big-leg-back.png';
import Tree from 'assets/images/pandanas-tree.png';

import WomanYellow from 'assets/images/sitting-woman-pink.png';
import WomanYellowPants from 'assets/images/woman-with-camera.png';
import WomanJeasRight from 'assets/images/womanDancing.png';
import ArmsUpDancer from 'assets/images/ArmsUpDancer.png';
import ManStanding from 'assets/images/man-standing.png';

import StarBurst from 'assets/images/SITC_2021_XRPopUp_FreeEntryExStarburst.png';
import DancingWomen from 'assets/images/TwoWomenPink.png';
import DancingGuy from 'assets/images/dancing-couple.png';
import TwoDancers from 'assets/images/couple-girl-with-beanie.png';
import DancingCopule from 'assets/images/couple-dancing.png';

import HeroBgVideo from 'assets/videos/hero_bg.mp4';
import Image from 'components/Image';

const AnnounceHero: React.FunctionComponent<any> = ({ children, pageHasAnnouncement }) => (
  <Layout.Section>
    <StyledContent isFirstSection={true} pageHasAnnouncement={pageHasAnnouncement}>
      <Layout.ContentInner>
        <HeroBackground>
          
          <Image className="starBurst" sourceUrl={StarBurst} />
          <Image className="ss-tag" sourceUrl={SSPresents} />
          <LogoImage />
          <HeroLandscape>
            <Image className="mountain-left" sourceUrl={MountainLeft} />
            <Image className="mountain-right" sourceUrl={MountainRight} />
            <Image className="tree" sourceUrl={Tree} />
            <Image className="trees" sourceUrl={Trees} />
          </HeroLandscape>
            <Image className="bgLegLeft" sourceUrl={LeftLeg} />
            <Image className="bgLegRight" sourceUrl={RightLeg} />
            <BgVideo autoPlay={true} muted={true} controls={false} loop={true}>
              <source src={HeroBgVideo} type="video/mp4" />
            </BgVideo>
        </HeroBackground>
        <BlockBackground>
          {children}
          <Image className="dancingGuy" sourceUrl={DancingGuy} />
          <Image className="twoDancers" sourceUrl={TwoDancers} />
          <Image className="dancingCouple" sourceUrl={DancingCopule} />
          <Image className="dancingWomen" sourceUrl={DancingWomen} />
          <Image className="manStanding" sourceUrl={ManStanding} />
          <Image className="armsUpDancer" sourceUrl={ArmsUpDancer} />
          <Image className="womanYellow" sourceUrl={WomanYellow} />
          <Image className="womanYellowPantsLeft" sourceUrl={WomanYellowPants} />
          <Image className="womanJeasRight" sourceUrl={WomanJeasRight} />
        </BlockBackground>
      </Layout.ContentInner>
    </StyledContent>
  </Layout.Section>
);

export default AnnounceHero;
