import React from 'react';
import BlockPage from 'layout/components/BlockPage';
import BlockSection from 'sections/Block';
import FrontpageHeroSection from 'sections/FrontpageHero';
import FrontPageWrapper from './styles';

class FrontPage extends BlockPage {
  renderChildren() {
    const { content } = this.props;

    return (
      <FrontPageWrapper>
        {content &&
          content.flexibleContent &&
          content.flexibleContent.contentBlocks.map((contentBlock, index) => {
            if (index === 0) {
              return (
                <>
                  <FrontpageHeroSection key={index} pageHasAnnouncement={this.pageHasAnnouncement()}>
                    {this.renderBlock(contentBlock)}
                  </FrontpageHeroSection>
                </>
              );
            } else if (index >= content.flexibleContent.contentBlocks.length - 1) {
              return (
                <BlockSection key={index} onVisible={() => this.updateBackgroundForIndex(index)}>
                  {this.renderBlock(contentBlock)}
                </BlockSection>
              );
            }

            return (
              <BlockSection key={index} onVisible={() => this.updateBackgroundForIndex(index)}>
                {this.renderBlock(contentBlock)}
              </BlockSection>
            );
          })}
      </FrontPageWrapper>
    );
  }
}

export default FrontPage;
