import styled, { css } from 'styled-components';
import { ThemeType } from 'types';
import isMobile from 'utils/isMobile';

interface Props extends ThemeType {
  image: string;
}

const logoWidth = isMobile() ? 50 : 50;

export const StyledLogoImage = styled.h1(
  ({ image, theme: { mobileView } }: Props) => css`
  position: relative;
  z-index: 10;
  display: block;
  font-size: 0;
  margin: 0 auto;
  width: ${logoWidth}vw;
  height: 0;
  padding-top: ${logoWidth * (400 / 1000)}vw;
  background-position: center;
  ${image ? `background-image: url(${image});` : ''}
  ${image ? 'background-size: contain;' : ''}
  ${image ? 'background-repeat: no-repeat;' : ''}
`,
);

const textWidth = logoWidth * 0.875;

export const StyledTextImage = styled.h2(
  ({ image }: Props) => css`
    position: relative;
    z-index: 10;
    display: block;
    font-size: 0;
    margin: -${textWidth * 0.14285}vw auto 3vw;
    width: ${textWidth}vw;
    height: 0;
    padding-top: ${textWidth * (347 / 936)}vw;
    ${image ? `background-image: url(${image});` : ''}
    ${image ? 'background-size: contain;' : ''}
    ${image ? 'background-repeat: no-repeat;' : ''}
  `,
);
